@import '../../variables';

.background {
    background: url('../../images/dashboard_blurred.png');
    height: 100vh;
    width: 100vw;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

.formContainer {
    margin-top: 6rem;

    @include media-breakpoint-down(md) {
        margin-top: 1rem;
    }
}

.logo {
    display: block;
    margin: $spacer * 5 auto;
    width: 80%;
}

.loginForm {
    :global .submitGroup {
        text-align: center;
        padding-top: 1rem;
    }

    @include media-breakpoint-up(md) {
        padding: 0 2rem;
    }
}

.resetPasswordLink {
    text-align: center;
}

.demoLink {
    margin-top: $spacer;
    text-align: center;
}
